<template>
    <div class="ModifyJod f-cl-c">
        <div class="head w--100">{{ headVal }}</div>
        <div class="w--60 mt-40 main">
            <div class="f-cl-c">
                <el-form class="f-w w--100" ref="ruleForm" :model="ruleForm" label-width="100px" :rules="JodResume">
                    <el-form-item class=" w--100" v-for="(item, index) of elFormItemArr" :label="item.label"
                        :prop="item.prop" :key="index">
                        <div v-if="item.prop == 'salary'">
                            <salarySelsect @handlesalarySelsect="handlesalarySelsect" :key="new Date().getTime()"
                                :min="ruleForm.minsalary" :max="ruleForm.maxsalary">
                            </salarySelsect>
                        </div>
                        <el-input v-else-if="item.ifTextarea" style="width: 550px;" type="textarea" :placeholder="item.placeholderValue" v-model="ruleForm[item.prop]" maxlength="500"
                            show-word-limit>
                        </el-input>
                        <el-input style="width: 340px;" v-else-if="item.is30WideCharacter" maxlength="30"
                            type="text" v-model="ruleForm[item.prop]"
                            :placeholder="item.placeholderValue"></el-input>
                        <div class="flex" v-else-if="item.prop == 'contactValue'">
                            <el-select style="width: 100px;" v-model="ruleForm.contactType">
                                <el-option v-for="item1 in item.contactTypeList" :key="item1.name" :label="item1.name" :value="item1.value">
                                    <div class="f-c-b">
                                        <img v-if="item1.name == '手机'"
                                            src="@/assets/Community/iPhoneIcon.png" alt="">
                                        <img v-else-if="item1.name == '微信'"
                                            src="@/assets/Community/WeChatIcon.png" alt="">
                                        <img v-else-if="item1.name == 'QQ'"
                                            src="@/assets/Community/QQIcon.png" alt="">
                                        <img v-else-if="item1.name == '邮箱'"
                                            src="@/assets/Community/MailboxIcon.png" alt="">
                                        <span>{{ item1.name }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                            <el-input class="ml-20" style="width: 220px;" v-model="ruleForm.contactValue"></el-input>
                        </div>
                        <div class="f-cl" v-else-if="item.prop == 'workAddress'">
                            <ChooseCity style="width: 340px !important;" :city="ruleForm.workLocation" @handleChangeCity="handleChangeCity"  :key="new Date().getTime()">
                            
                             </ChooseCity>
                            <el-input class="mt-20" style="width: 630px;" type="text" v-model="ruleForm.workAddress" placeholder="输入公司地址"></el-input> 
                        </div>
                    </el-form-item>
                </el-form>
                <div class="f-c-c mt-40">
                    <div class="btn1" @click="addForumRecruit('S')">保存</div>
                    <div class="ml-40 btn2" @click="addForumRecruit('N')">发布</div>
                    <div v-if="type == 'modlify'" class="ml-40 btn2" @click="removeForumRecruit">删除</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { JodResume } from "@/utils/FormRules";
export default {
    name: 'ModifyJod',
    data() {
        return {
            JodResume,
            headVal: '',
            type:'',
            detailInfo: {},
            ruleForm: {  // 职位内容
                position: '', // 职位名称
                salary:'',  // 薪资
                minsalary: '',
                maxsalary: '',
                postContent:'', // 岗位详情
                workLocation:'', // 地址
                workAddress: '', // 详细地址
                contact:'',  // 联系人
                contactType:'', // 联系方式
                contactValue:'', // 联系方式
            },
            elFormItemArr: [
                {
                    label: '职位名称',
                    prop: 'position',
                    placeholderValue: '请输入职位名称',
                    is30WideCharacter: true
                },
                {
                    label: '岗位薪资',
                    prop: 'salary',
                },
                {
                    label: '岗位详情',
                    prop: 'postContent',
                    placeholderValue: '填写类似格式:1、年龄22到35周岁;2、大专以上学历,有工作经验;3、有较强的的学习、沟通能力;4、有良好的团队合作意识和承压能力',
                    ifTextarea: true
                },
                {
                    label: '公司地址',
                    prop: 'workAddress',
                },
                {
                    label: '联系人',
                    prop: 'contact',
                    placeholderValue: '请输入联系人',
                    is30WideCharacter: true
                },
                {
                    label: '联系方式',
                    prop: 'contactValue',
                    contactTypeList: [
                        {
                            value: 'P',
                            name: '手机',
                            imgPath: '@/assets/Community/iPhoneIcon.png'
                        }, {
                            value: 'W',
                            name: '微信',
                            imgPath: '@/assets/Community/WeChatIcon.png'
                        }, {
                            value: 'Q',
                            name: 'QQ',
                            imgPath: '@/assets/Community/QQIcon.png'
                        }, {
                            value: 'M',
                            name: '邮箱',
                            imgPath: '@/assets/Community/MailboxIcon.png'
                        }
                    ],
                },
                
            ]
        }
    },
    computed: {
    },
    created() {
        let { detailInfo, type } = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.type = type;
        this.detailInfo = detailInfo;
        console.log(detailInfo);
        this.headVal = type == 'add' ? '新增职位' : '修改职位';
        if (type == 'modlify') {
            let { position, salary, postContent, workLocation, workAddress, contact, contactType, contactValue} = detailInfo;
            let salaryList = [];
            if(salary != '面议') {
                salaryList = salary.split(" - ");
            }
            this.ruleForm = {
                position,
                salary,
                maxsalary: salary == '面议' ? '面议' : salaryList[1],
                minsalary: salary == '面议' ? '面议' : salaryList[0],
                postContent,
                workLocation: workLocation.split(" "),
                workAddress,
                contact,
                contactType,
                contactValue
            }
        }
    },
    methods: {
        handlesalarySelsect(value, min, max) {
            this.ruleForm.salary = value;
            this.ruleForm.minsalary = min;
            this.ruleForm.maxsalary = max;
        },
         // 选中省市区
         handleChangeCity(value) {
            this.ruleForm.workLocation = value
        },
        addForumRecruit(saveState){
            let that = this,
                ruleFormValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid) {
                
                that.$confirm('确定新增？', '提示').then(() => {
                    let data = that.ruleForm;
                    data.saveState = saveState;
                    data.postLocation = that.$getStorage('getIpAddress').fcommentLocation;
                    data.workLocation = data.workLocation.join(' ');
                    delete data.maxsalary;
                    delete data.minsalary;
                    that.$http.addForumRecruit({
                        ...data,
                        postUserId: parseInt(that.$store.state.userId),
                        token: that.$getStorage('token'),
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-1);
                    })
                }).catch(() => { })
            }
        },
        removeForumRecruit(){
            let that = this;
            that.$confirm('确定删除？', '提示').then(() => {
                    that.$http.removeForumRecruit({
                        postId: that.detailInfo.postId,
                        postUserId: parseInt(that.$store.state.userId),
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-2);
                    })
                }).catch(() => { })
        }
    },
}

</script>
<style lang='less' scoped>
.ModifyJod {
    .head{
        text-align: left;
        font-weight: 500;
        font-size: 36px;
        color: #000000;
    }
    .main{
        .btn1 {
            width: 160px;
            height: 60px;
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            line-height: 60px;
            text-align: center;
            color: #fff;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;
        }
        // EFF4FA
        .btn2 {
            width: 160px;
            height: 60px;
            background: #EFF4FA;
            line-height: 60px;
            text-align: center;
            color: #0363FA;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;
        }
    }

}
</style>